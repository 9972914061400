import Uppy from '@uppy/core';
import Compressor from '@uppy/compressor';
import Dashboard from '@uppy/dashboard';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import AwsS3 from '@uppy/aws-s3';

import I18n from 'i18n-js';

class TcMultiFileUpload extends HTMLElement {
  static formAssociated = true;

  constructor() {
    super();
    this._internals = this.attachInternals();
    this._value = null;
    this.locale = buildUppyLocale();
  }

  get value() { return this._value; }
  set value(value) {
    this._value = value;
    this._internals.setFormValue(JSON.stringify(this._value));
    this.validate();
  }

  get form() { return this._internals.form; }
  get name() { return this.getAttribute('name'); }
  get type() { return this.localName; }
  get validity() { return this._internals.validity; }
  get validationMessage() { return this._internals.validationMessage; }
  get willValidate() { return this._internals.willValidate; }

  checkValidity() { return this._internals.checkValidity(); }
  reportValidity() { return this._internals.reportValidity(); }

  get allowedFileTypes() {
    if (this.dataset.accept) {
      return this.dataset.accept.split(',').map(type => type.trim());
    } else {
      return null;
    }
  }

  connectedCallback() {
    // we call this on connection rather than on init because we
    // want to make sure we have the correct value of the required attr
    this.validate();

    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 100,
        minNumberOfFiles: 1,
        minFileSize: 1,
        allowedFileTypes: this.allowedFileTypes,
      },
      locale: this.locale,
    })
      .use(Dashboard, {
        target: this,
        inline: true,
        width: '100%',
        height: 'max(60vh, 10rem)',
        hideUploadButton: true,
        proudlyDisplayPoweredByUppy: false,
        maxNumberOfFiles: 100,
        minNumberOfFiles: 1,
        locale: this.locale,
        showNativePhotoCameraButton: true,
        disableInformer: true,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 600,
      })
      .use(AwsS3, {
        companionUrl: '/', // will call the presign endpoint on `/s3/params`
      })
      .use(Compressor, {
        quality: 0.8,
      });

    // lookit all the events
    this.uppy.on('file-added', this.onFileAdded.bind(this));
    this.uppy.on('file-removed', this.onFileRemoved.bind(this));
    this.uppy.on('complete', this.onUploadSuccess.bind(this));
    this.uppy.on('cancel-all', this.onCancel.bind(this));
  }

  onFileAdded() {
    this.validate();
    this.dispatchEvent(new Event('change'));
  }

  onFileRemoved() {
    this.validate();
    this.dispatchEvent(new Event('change'));
  }

  onProgress(progress) {
    this.progressIndicator.value = progress;
  }

  onError() {
    // emit error message
    this.error = I18n.t('file_uploader.must_upload_successfully');
    this.validate();
    this.reportValidity();

    // un-error things, this is very hacky but it works
    this.form['form-validation'].undisable();
    this.error = null;
    this.validate();
  }

  onCancel() {
    this.validate();
  }

  remove(event) {
    this.uppy.cancelAll();
  }

  validate() {
    let message;
    const issues = {
      valueMissing: false,
      customError: false,
    }

    const required = !this.matches(':disabled') && this.hasAttribute('required');
    const noFiles = !this.uppy || this.uppy.getFiles().length === 0;
    if (required && noFiles) {
      message = I18n.t('file_uploader.must_be_attached');
      issues.valueMissing = true;
    } else if (this.error) {
      message = this.error;
      issues.customError = true;
    }
    this._internals.setValidity(issues, message);
  }

  onUploadSuccess(result) {
    if (result.failed.length > 0) {
      this.onError();
    }

    this.value = result.successful.map(file => ({
      id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    }))
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.uppy.getFiles().length > 0) {
      this.uppy.upload().then((result) => {
        if (result && result.successful.length > 0) {
          event.target.submit();
        } else {
          this.onError();
        }
      })
    } else {
      // // the validity stuff SHOULDN'T matter;
      // // this should just be part of the browser validation cycle.
      // // something wonky is going up in bootstrap modals
      // // this code can probably removed after switching to Turbo modals
      this.reportValidity();
      if (this.checkValidity()) {
        event.target.submit();
      }
    }
  }

  disconnectedCallback() {
    this.uppy.close();
    this.form.removeEventListener(this.formEventListener);
  }

  formAssociatedCallback(form) {
    this.formEventListener = this.form.addEventListener('submit', this.onSubmit.bind(this));
  }

  formCancelledCallback(form) {
    if (this.matches(':disabled')) {
      this.uploadButton.setAttribute('disabled', true);
      this.cancelButton.setAttribute('disabled', true);
      this.removeButton.setAttribute('disabled', true);
    } else {
      this.uploadButton.setAttribute('disabled', false);
      this.cancelButton.setAttribute('disabled', false);
      this.removeButton.setAttribute('disabled', false);
    }
  }

  formResetCallback(form) {
    this.uppy.cancelAll();
  }

  formStateRestoreCallback(state, mode) {
    this.value = state;
  }
}

customElements.define('tc-multi-file-upload', TcMultiFileUpload);

function buildUppyLocale() {
  return {
    strings: {
      chooseFiles: I18n.t('file_uploader.choose_file'),
    },
  }
}
