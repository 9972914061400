import $ from 'jquery'
import moment from 'moment'
import React from 'react'
import ReactDOM from 'react-dom'
import autosize from 'autosize'
import each from 'lodash/each'

import { schoolUrlHelper } from './school_url_helper'
import { spit } from './debug'
import './logging'

import { secondary } from '../navigation'
import { emailValidation } from 'shared/validation'
import { setupRoutes } from 'shared/routes'
import { initCheckboxGroupRequired, initMultipleSelect } from 'shared/form_helper'
import { initModalListeners } from 'shared/modal_helpers'
import { ajaxErrorHandler } from 'shared/error_handling'

require('construct-style-sheets-polyfill');
require('element-internals-polyfill');

// const { DuetDatePicker } = require('@duetds/date-picker/custom-element');

// customElements.define('duet-date-picker', DuetDatePicker);

// helpers for dev debugging
if (process.env.NODE_ENV !== 'production') {
  window.spit = spit
  // window.R = require('ramda')
}

export function onClickOutside(selector, cb) {
  // Close when clicking outside window: https://stackoverflow.com/a/3028037/2672869
  const outsideClickListener = (e) => {
    if (!$(e.target).closest(selector).length) {
      cb()
      removeClickListener() // eslint-disable-line no-use-before-define
    }
  }

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener)
  }

  document.addEventListener('click', outsideClickListener)

  return removeClickListener
}

export function initDatePickers() {
  // turn off listeners if they were present, to keep this function idempotent
  $(document).off('click', '.clear-date-field')

  $('.date-field').datepicker({
    orientation: 'bottom auto',
  })
  $('.input-daterange').datepicker({
    orientation: 'bottom auto',
  })

  $(document).on('click', '.clear-date-field', (e) => {
    const $dateField = $(e.target).parents('.input-group.date').find('.date-field')
    $dateField.datepicker('clearDates')
  })
}

/**
 * Detect IE. Returns version of IE or false, if browser is not Internet Explorer.
 * Source: https://codepen.io/gapcode/pen/vEJNZN
 */
export function detectIE() {
  const ua = window.navigator.userAgent

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return false
}

/**
 * react says not to depend on the return from render, so this may break
 * future code if render stops returning the component
 */
export const renderReactComponent = (Component, el, props = {}) => {
  setupRoutes()
  return ReactDOM.render(<Component {...props} />, el) // eslint-disable-line react/no-render-return-value
}

export const initDeleteConfirmModal = () => {
  // context matters, don't use an arrow function
  const $btn = $('a[data-delete-confirm]')
  $btn.off('click').click(function deleteConfirmModal() {
    const $this = $(this)
    const warning = $this.attr('data-delete-confirm')
    const href = $this.attr('href')
    const text = $this.attr('data-confirm') || I18n.t('actions.delete')
    const $modal = $(`
  <div class="modal fade" id="delete-confirm-modal" tabindex="-1" role="dialog" aria-labelledby="delete-confirm-modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">${warning}</div>
        <div class="modal-footer">
          <a href="${href}" data-method="DELETE" class="btn btn-danger confirm-delete">${text}</a>
          <button class="btn btn-secondary" type="button" data-dismiss="modal" aria-hidden="true">
            ${I18n.t('actions.cancel')}
          </button>
        </div>
      </div>
    </div>
  </div>
    `)
    $modal.modal()
    return false
  })
}

export const initializeSerializeForRails = () => {
  function strEndsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1
  }

  /**
   * Old version of serializing form for Rails. You probably don't want this one, you want
   * `serializeForRailsIncludingCheckboxes` instead, unless you don't want to capture unchecked
   * checkboxes.
   * @return {{}}
   */
  $.fn.serializeForRails = function serializeForRails() {
    const map = {}
    each($(this).serializeArray(), (kv) => {
      if (strEndsWith(kv.name, '[]')) {
        const key = kv.name.replace('[]', '')
        if (!map[key]) {
          map[key] = []
        }
        map[key].push(kv.value)
      } else {
        map[kv.name] = kv.value
      }
    })
    return map
  }
}

// on Chrome for Android, scrollTop can return float rather than integer values,
// so we have to round it up to avoid off-by-one errors. see https://app.clubhouse.io/transparentclassroom/story/6028
export const getScrollTop = elem => (
  Math.ceil(elem.scrollTop)
)

export function isElementBelowFold(element) {
  const { top, bottom } = element.getBoundingClientRect();
  const vHeight = (window.innerHeight || document.documentElement.clientHeight);

  return (
    (top > 0 || bottom > 0) &&
    top < vHeight
  );
}

export function autocollapseStuffOnSmallScreens() {
  const elementsThatShouldStartAboveFold = Array.from(document.querySelectorAll('[data-start-above-fold]'));
  if (elementsThatShouldStartAboveFold.some(isElementBelowFold)) {
    collapseAutocollapsableElements();
  }
}

export function collapseAutocollapsableElements() {
  const autocollapsableSummaries = document.querySelectorAll('summary[data-autocollapsable]');
  autocollapsableSummaries.forEach(e => e.open = false);
}

export function initUppyWebComponent() {
  document.querySelectorAll('input[type=file][data-is=tc-file-upload]').forEach((fileField) => {
    const enhanced = document.createElement('tc-file-upload');
    enhanced.setAttribute('id', fileField.id);
    enhanced.setAttribute('name', fileField.name);
    enhanced.setAttribute('required', fileField.required);
    enhanced.dataset.accept = fileField.accept;
    fileField.parentNode.insertBefore(enhanced, fileField);
    fileField.remove();
  });
}

/* eslint-disable no-param-reassign */
function init() {
  $.rails.refreshCSRFTokens();

  /**
   * Throws when the selector doesn't return an element. Useful for when you're expecting something
   * to be there and make sure it's there, and to alert the dev team when it's not. Especially
   * useful when querying for selectors that live in other files.
   */
  $.fn.findOrThrow = function findOrThrow(sel) {
    const $res = $(this).find(sel)
    if (!$res.length) throw new Error(`Expected to find element with selector "${sel}" but none found`)
    return $res
  }

  $.fn.removeSlowly = function removeSlowly() {
    const $el = $(this)
    $el.hide('slow', () => $el.remove())
  }

  $.fn.loading = function loading() {
    $(this).html('<div class=\'loading-sm\'></div>')
    return this
  }
  $.fn.loadingLarge = function loadingLarge() {
    $(this).html('<div class=\'loading-lg\'></div>')
    return this
  }
  $.fn.loadingModalLarge = function loadingModalLarge() {
    if ($(this).height() > window.innerHeight) {
      $(this).addClass('modal-trim-height')
      $(this).css('max-height', window.innerHeight)
    }
    $(this).append('<div class="modal-spinner"><div class="loading-lg"></div></div>')
    return this
  }

  $.fn.startLoading = function startLoading() {
    this.after('<div class=\'loading\'></div>')
    this.hide()
  }

  $.fn.stopLoading = function stopLoading() {
    this.next('.loading').remove()
    this.show()
  }

  $.fn.selectRange = function selectRange(start, end) {
    this.each(() => {
      if (this.setSelectionRange) {
        this.focus()
        this.setSelectionRange(start, end)
      } else if (this.createTextRange) {
        const range = this.createTextRange()
        range.collapse(true)
        range.moveEnd('character', end)
        range.moveStart('character', start)
        range.select()
      }
    })
  }

  $.fn.placeCursorAtEnd = function placeCursorAtEnd() {
    const end = $(this).val().length
    return $(this).selectRange(end, end)
  }

  $.fn.insertAtCaret = function insertAtCaret(myValue) {
    return this.each((i) => {
      if (document.selection) {
        // For browsers like Internet Explorer
        this.focus()
        const sel = document.selection.createRange()
        sel.text = myValue
        this.focus()
      } else if (this.selectionStart || this.selectionStart === '0') {
        // For browsers like Firefox and Webkit based
        const startPos = this.selectionStart
        const endPos = this.selectionEnd
        const scrollTop = this.scrollTop
        this.value = this.value.substring(0, startPos) + myValue + this.value.substring(endPos, this.value.length)
        this.focus()
        this.selectionStart = startPos + myValue.length
        this.selectionEnd = startPos + myValue.length
        this.scrollTop = scrollTop
      } else {
        this.value += myValue
        this.focus()
      }
    })
  }
  initializeSerializeForRails()

  /**
   * Serializes the data in the form, and captures unchecked checkboxes, defaulting them to '0'.
   * @return {{}}
   */
  $.fn.serializeForRailsIncludingCheckboxes = function serializeForRailsIncludingCheckboxes() {
    const map = $(this).serializeForRails()
    $(this).find('input:checkbox:not(:checked)').each(function giveCheckboxDefaultValue() {
      map[$(this).attr('name')] = '0'
    })
    return map
  }

  window.sanitize = text => $('<div/>').text(text).html()

  window.dateToYMD = date => moment(date).format('Y-MM-DD')

  window.presentLesson = (classroomId, lessonId, childId, proficiency) => {
    const changes = {}
    changes[lessonId] = {}
    changes[lessonId][childId] = { proficiency, planned: false }

    $.ajax({
      url: schoolUrlHelper(`classrooms/${classroomId}/levels.json?log=lesson-plan-present-lesson`),
      method: 'PUT',
      data: { changes },
    })
  }

  /**
   * open email user page in new window
   * @param options
   *            - child_ids [Array]: selected child IDs to email parents
   *            - user_ids [Array]: selected user IDs to email
   *            - via [String]: the method for messaging ('email' or 'sms')
   */
  window.emailUsers = (options) => {
    let url = `emails?locale=${I18n.locale}&via=${options.via}`
    if (options.child_ids) {
      url += `&child_ids=${options.child_ids.join()}`
    } else {
      url += `&user_ids=${options.user_ids.join()}`
    }

    window.open(schoolUrlHelper(url), 'email')
  }

  $.fn.tcfancybox = function tcfancybox() {
    $(this).fancybox({
      parent: 'body',
      helpers: {
        title: { type: 'inside' },
        overlay: { locked: false },
      },
      beforeShow() {
        const post = $(this.element).parents('.Post')
        const content = post.find('.PostBody__content')
        // extract data from DOM element and insert it into the fancybox display
        if (content.length > 0) {
          const date = post.find('.rc-date__label')
          const author = post.find('.PostBody__author')
          const reactionsHtml = (() => {
            const thumbsUpReactions = post.find('.PostBody__thumbs-up-reactions')
            const heartReactions = post.find('.PostBody__heart-reactions')
            if (thumbsUpReactions.length > 0) {
              return `<p>${thumbsUpReactions.html()} ${heartReactions.html()}</p>`
            } else {
              return ''
            }
          })()
          const responseHtml = (() => {
            const responseContent = post.find('.PostBody__content--response')
            const responseDate = post.find('.rc-date__label--response')
            const responseAuthor = post.find('.PostBody__author--response')

            if (responseContent.length > 0) {
              return `${responseContent.html()} ${responseDate.html()} ${responseAuthor.html()}`
            } else {
              return ''
            }
          })()

          const postHtml = `<p>${content.html()} ${date.html()} ${author.html()}</p>`
          this.title = `<div class="myTitle">${reactionsHtml}${postHtml}${responseHtml}</div>`
        }
      },
      afterShow() {
        const original = $(this.element).attr('data-original')
        // Ensure `original` is actually a string, it blows up when an object: https://trello.com/c/1hhCSyCT
        if (original && typeof original === 'string') {
          $(`
            <div class="full-size-link">
<!--
              <a href='${original}' target='_blank' class='fancybox-photo-fullsize'><i class="fas fa-expand-alt" />
</a>-->
              <a href='${original}' target="_blank" rel="noopener noreferrer" class='fancybox-photo-download'>
                <i class="fas fa-download" />
              </a>
            </div>
          `).appendTo(this.outer)
        }
      },
    })
  }

  $.hoverable = function hoverable(selector) {
    $('body').on({
      mouseenter() {
        $(this).addClass('hover')
      },
      mouseleave() {
        $(this).removeClass('hover')
      },
    }, selector)
  }

  /**
   * Scrolls to the jQuery-wrapped element. Can specify a container if the element is within a
   * scrollable container.
   *
   * Based off code from https://stackoverflow.com/a/44660369/2672869
   */
  $.fn.scrollToAnimated = function scrollTo(container = $('html, body'), padding = 0, speed = 1000) {
    container.animate({
      scrollTop: parseInt(
        container.scrollTop() + (($(this).offset().top - padding) - container.offset().top),
        10,
      ),
    })
  }

  // anytime you press '/', go to the search box
  $(document).on('keyup', null, '/', (e) => {
    if ($(e.target).attr('contenteditable')) return

    $('.search-query').focus()
  })

  secondary.startAutosizing()

  $(() => {
    setupRoutes()

    // force a recompile...

    autosize($('textarea.autogrow'))

    // initialize popovers
    $('[data-toggle="popover"]').popover()

    $('img[rel=\'tooltip\']').tooltip({ placement: 'bottom' })

    // we want this dropdown to use the correct boundary for its scroll behavior, but we couldn't find a way
    // to select a literal html element while constructing this dropdown in application_helper.rb,
    // so we have to do it here.
    $('.secondary-nav .dropdown-toggle').dropdown({ boundary: $('.page-wrap')[0] })

    // $(".proficiency").tooltip();

    $('.help').tooltip()

    $('.use-modal').tcfancybox()

    initDatePickers()
    autocollapseStuffOnSmallScreens()

    initUppyWebComponent()
    initMultipleSelect()
    initCheckboxGroupRequired()
    initModalListeners()

    $.hoverable('.posts .post')
    $.hoverable('.profile-photo')

    $('form.with-validation').validate({
      // custom rules, list out input `name`s that require special rules, e.g. email validation
      rules: {
        ['trial_form[staff][][email]']: emailValidation,
      },
    })

    initDeleteConfirmModal()
    ajaxErrorHandler.setupFallback()

    /**
     * Functionality that automatically slideToggles a view when a thing is clicked.
     * Usage: put `slide-toggle-trigger-[id]` on the thing to watch for clicks, and `slide-toggle-display-[id]` on the
     * thing to toggle the view.
     */
    $('[class*="slide-toggle-trigger"]').click((evt) => {
      if (evt.target.className.indexOf('progressive') !== -1) $(evt.target).hide()
      // grab the classname, e.g. 'slide-toggle-trigger-foo'
      const cname = evt.target.className.split(' ').filter(e => /slide-toggle-trigger-/.test(e))[0]

      /**
       * Just in case this click handler catches an event that wasn't actually on a slide-toggle-trigger- element,
       * do nothing.
       */
      if (!cname) return

      // pull the identifier out, e.g. 'foo'
      const identifier = cname.match(/slide-toggle-trigger-(.*)/)[1]
      // grab the corresponding display and toggle the display
      $(`.slide-toggle-display-${identifier}`).slideToggle()
    })
  })
}

/* eslint-enable no-param-reassign */

export default { init }
