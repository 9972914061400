export class ChartHelper {
  constructor() {
    this.today = new Date()
    this.today = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate())
  }

  daysAgo(date) {
    return Math.floor((this.today - new Date(date)) / (24 * 60 * 60 * 1000))
  }

  dayLabels(howMany) {
    const labels = []
    for (let i = 0, end = howMany; i < end; i++) {
      const date = new Date(this.today.getUTCFullYear(), this.today.getUTCMonth(), this.today.getUTCDate() - i)
      labels.push(`${date.getMonth() + 1}/${date.getDate()}`)
    }
    return labels
  }
}
