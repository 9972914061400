const debug = (message) => { console.debug('ConfirmDepartureHandler', message) } // eslint-disable-line no-console
export default class ConfirmDepartureHandler {
  constructor(component, window, document) {
    this.component = component;
    this.window = window;
    this.document = document;
    this.bindEvents();
  }

  bindEvents() {
    debug('Binding Events');
    this.document.addEventListener('turbo:before-visit', this);
    this.window.addEventListener('beforeunload', this);
    debug('Events Bound');
  }

  handleEvent(evnt) {
    debug({ evnt, props: this.component.props })
    // eslint-disable-next-line no-alert
    if (this.component.props.dirty && !window.confirm(I18n.t('general.unsaved_changes'))) {
      evnt.preventDefault();
    } else {
      this.unbindEvents();
    }
  }

  unbindEvents() {
    debug('Unbinding Events');
    this.document.removeEventListener('turbo:before-visit', this);
    this.window.removeEventListener('beforeunload', this);
    debug('Events Unbound');
  }
}
