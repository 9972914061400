import Uppy from '@uppy/core';
import Compressor from '@uppy/compressor';
import FileInput from '@uppy/file-input';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import AwsS3 from '@uppy/aws-s3';

import I18n from 'i18n-js';

class TcFileUpload extends HTMLElement {
  static formAssociated = true;

  constructor() {
    super();
    this._internals = this.attachInternals();
    this._value = null;
    this.locale = buildUppyLocale();
  }

  get value() { return this._value; }
  set value(value) {
    this._value = value;
    this._internals.setFormValue(JSON.stringify(this._value));
    this.validate();
  }

  get form() { return this._internals.form; }
  get name() { return this.getAttribute('name'); }
  get type() { return this.localName; }
  get validity() { return this._internals.validity; }
  get validationMessage() { return this._internals.validationMessage; }
  get willValidate() { return this._internals.willValidate; }

  checkValidity() { return this._internals.checkValidity(); }
  reportValidity() { return this._internals.reportValidity(); }

  get allowedFileTypes() {
    if (this.dataset.accept) {
      return this.dataset.accept.split(',').map(type => type.trim());
    } else {
      return null;
    }
  }

  connectedCallback() {
    this.attachShadow({ mode: 'open' });
    // we call this on connection rather than on init because we
    // want to make sure we have the correct value of the required attr
    this.validate();

    // "some assembly required" -- the next few blocks of code are
    // what us not using a templating framework means
    this.preview = document.createElement('img');
    this.uppyTarget = document.createElement('div');
    this.removeButton = document.createElement('button');
    this.progressPane = document.createElement('div');
    this.progressIndicator = document.createElement('progress');
    this.cancelButton = document.createElement('button');

    this.shadowRoot.append(this.preview);
    this.shadowRoot.append(this.uppyTarget);
    this.shadowRoot.append(this.removeButton);
    this.shadowRoot.append(this.progressPane);

    this.progressPane.append(this.progressIndicator);
    this.progressPane.append(this.cancelButton);
    this.cancelButton.innerHTML = 'Cancel';

    // eslint-disable-next-line max-len
    this.removeButton.innerHTML = '<span part="sr-only">Remove file</span><svg role="presentation" viewBox="0 0 700 500" xmlns="http://www.w3.org/2000/svg"><path d="m266 83.973v28.055c0-0.027344 0-0.027344-0.082031-0.027344h168.16c-0.070312 0-0.082031 0.011719-0.082031 0.027344v-28.055c0 0.027344 0 0.027344 0.082031 0.027344h-168.16c0.070312 0 0.082031-0.011719 0.082031-0.027344zm-84.023 56.027h-27.977v-28h84v-28.027c0-15.449 12.523-27.973 27.918-27.973h168.16c15.418 0 27.918 12.574 27.918 27.973v28.027h84v28h-27.977l-0.046876 1.1641-13.418 321.99c-0.94531 22.688-19.727 40.844-42.465 40.844h-224.19c-22.738 0-41.52-18.148-42.465-40.844l-13.418-321.99zm28.023 0 13.418 321.99c0.32031 7.7422 6.8086 14.008 14.488 14.008h224.19c7.6758 0 14.168-6.2734 14.488-14.008l13.418-321.99zm182.1 56v224h28v-224zm-56.102 0v224h28v-224zm-56.102 0.22266v224h28v-224z" fill-rule="evenodd"/></svg>';

    this.preview.setAttribute('part', 'preview');
    this.uppyTarget.setAttribute('part', 'upload-pane');
    this.cancelButton.setAttribute('part', 'button');
    this.removeButton.setAttribute('part', 'remove');
    this.progressPane.setAttribute('part', 'progress');
    this.progressIndicator.setAttribute('max', 100);

    // finally something other than templating

    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        minFileSize: 1,
        allowedFileTypes: this.allowedFileTypes,
      },
      locale: this.locale,
    })
      .use(FileInput, {
        target: this.uppyTarget,
        locale: this.locale,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 600,
      })
      .use(AwsS3, {
        companionUrl: '/', // will call the presign endpoint on `/s3/params`
      })
      .use(Compressor, {
        quality: 0.8,
      });

    // oops, more templating
    // we need to set this up special because uppy inits this button, not us
    this.uploadButton = this.shadowRoot.querySelector('.uppy-FileInput-btn');
    this.uploadButton.setAttribute('part', 'button');

    if (window.navigator.userAgent.match('Turbo Native Android')) {
      // Add the capture attribute
      // (https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/capture)
      // so users on Android will get the option of using their camera instead
      // of choosing an existing file. (See:
      // https://github.com/hotwired/turbo-android/pull/141)
      // But don't add the attribute for iOS users because they already have
      // that option and the capture attribute takes away their ability to
      // choose existing files.
      this.fileInput = this.shadowRoot.querySelector('.uppy-FileInput-input');
      this.fileInput.setAttribute('capture', 'environment');
    }

    // theoretically our asset bundler should do this for us
    // theoretically webpack 4 can do this for us
    // in practice, something about our Q3 2022 webpack setup...
    // ... is not doing this for us
    this.sheet = new CSSStyleSheet();
    this.sheet.insertRule(':host { contain: strict; width: var(--width); height: var(--height); }');
    this.shadowRoot.adoptedStyleSheets = [this.sheet];

    // lookit all the events
    this.removeButton.addEventListener('click', this.remove.bind(this));
    this.cancelButton.addEventListener('click', this.uppy.cancelAll.bind(this.uppy));
    this.uppy.on('thumbnail:generated', this.onThumbnailGenerated.bind(this));
    this.uppy.on('file-added', this.onFileAdded.bind(this));
    this.uppy.on('file-removed', this.onFileRemoved.bind(this));
    this.uppy.on('upload', this.onUploading.bind(this));
    this.uppy.on('upload-success', this.onUploadSuccess.bind(this));
    this.uppy.on('progress', this.onProgress.bind(this));
    this.uppy.on('cancel-all', this.onCancel.bind(this));

    this.dataset.formValidationTarget = 'input'
    this.dataset.action = 'change->form-validation#fileInputChange'
  }

  onThumbnailGenerated(file, preview) {
    this.preview.src = preview;
  }

  onFileAdded() {
    // eslint-disable-next-line max-len
    this.preview.src = 'data:image/svg;utf8,<svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="10" /></svg>';
    this.dataset.state = 'has-photo';
    this.validate();
    this.dispatchEvent(new Event('change'));
  }

  onFileRemoved() {
    this.preview.src = ''
    this.dataset.state = 'empty';
    this.value = null;
    this.validate();
    this.dispatchEvent(new Event('change'));
  }

  onUploading() {
    this.dataset.state = 'uploading';
  }

  onProgress(progress) {
    this.progressIndicator.value = progress;
  }

  onError() {
    this.dataset.state = 'has-photo';

    // reset state of uploader
    const files = this.uppy.getFiles();
    const preview = this.preview.src;
    this.uppy.cancelAll();
    this.uppy.addFiles(files);
    this.preview.src = preview;

    // emit error message
    this.error = I18n.t('file_uploader.must_upload_successfully');
    this.validate();
    this.reportValidity();

    // un-error things, this is very hacky but it works
    this.form['form-validation'].undisable();
    this.error = null;
    this.validate();
  }

  onCancel() {
    this.dataset.state = 'has-photo';
    this.validate();
  }

  remove(event) {
    this.uppy.cancelAll();
  }

  validate() {
    let message;
    const issues = {
      valueMissing: false,
      customError: false,
    }

    const required = !this.matches(':disabled') && this.hasAttribute('required');
    const noFiles = !this.uppy || this.uppy.getFiles().length === 0;
    if (required && noFiles) {
      message = I18n.t('file_uploader.must_be_attached');
      issues.valueMissing = true;
    } else if (this.error) {
      message = this.error;
      issues.customError = true;
    }
    this._internals.setValidity(issues, message);
  }

  onUploadSuccess(file, response) {
    this.dataset.state = 'done';
    this.value = {
      id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    }
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.uppy.getFiles().length > 0) {
      this.uppy.upload().then((result) => {
        if (result && result.successful.length > 0) {
          // form.submit() doesn't work in Turbo-iOS, so instead of doing
          // something sensible here, like `event.target.submit()`, we are going
          // to go through this rigamorole with XMLHttpRequest.

          const formData = new FormData(event.target);
          const request = new XMLHttpRequest();
          request.open('POST', event.target.action)
          request.send(formData)
          window.Turbo.visit(request.responseURL)
        } else {
          this.onError();
        }
      })
    } else {
      // // the validity stuff SHOULDN'T matter;
      // // this should just be part of the browser validation cycle.
      // // something wonky is going up in bootstrap modals
      // // this code can probably removed after switching to Turbo modals
      this.reportValidity();
      if (this.checkValidity()) {
        event.target.submit();
      }
    }
  }

  disconnectedCallback() {
    this.uppy.close();
    this.form.removeEventListener(this.formEventListener);
  }

  formAssociatedCallback(form) {
    this.formEventListener = this.form.addEventListener('submit', this.onSubmit.bind(this));
  }

  formCancelledCallback(form) {
    if (this.matches(':disabled')) {
      this.uploadButton.setAttribute('disabled', true);
      this.cancelButton.setAttribute('disabled', true);
      this.removeButton.setAttribute('disabled', true);
    } else {
      this.uploadButton.setAttribute('disabled', false);
      this.cancelButton.setAttribute('disabled', false);
      this.removeButton.setAttribute('disabled', false);
    }
  }

  formResetCallback(form) {
    this.uppy.cancelAll();
  }

  formStateRestoreCallback(state, mode) {
    this.value = state;
  }
}

customElements.define('tc-file-upload', TcFileUpload);

function buildUppyLocale() {
  return {
    strings: {
      chooseFiles: I18n.t('file_uploader.choose_file'),
    },
  }
}
