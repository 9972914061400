import { isNil, join, map, path, pipe, reject, values } from 'shared/ramda_loader'
import { tForFile } from './i18n'
import { isBlank } from 'shared/string_helpers'
import { isProduction } from 'shared/is_production'

const t = tForFile('web.shared.error_handling')

// based off https://github.com/axios/axios/issues/383#issuecomment-316501886
// and https://github.com/axios/axios/issues/2103#issuecomment-501029971
export const isAxiosNetworkError = e => (
  e.code === 'ECONNABORTED' || (isNil(e.response) && e.message === 'Network Error')
)
export const isAxiosServerError = e => path(['response', 'status'], e) >= 500
export const isAxiosNotFound = e => path(['response', 'status'], e) === 404

export const isNetworkError = xhr => xhr.status === 0
export const isServerError = xhr => xhr.status >= 500
export const isUserError = xhr => xhr.status >= 400 && xhr.status < 500

const createAjaxErrorHandler = () => {
  let handled = false

  const isHandled = () => handled
  const markHandled = () => { handled = true }
  const reset = () => { handled = false }

  /**
   * Allows client to provide a handler callback, which is given the jquery event and error XHR.
   * When the AJAX error has already been handled, the callback isn't invoked. If it hasn't been handled, the
   * callback is invoked and marks the error as having been handled.
   */
  const handle = cb => (event, err) => {
    if (isHandled()) return
    markHandled()
    cb(event, err)
  }
  /**
   * Used to generate an error handler callback when invoking `$.ajax({ ..., error: createAjaxErrorCallback })`.
   * The parameters passed to the `error` callback is tragically different from the parameters pass to an
   * `.on('ajax:error', ...)` callback, so we need to do a little data piping here.
   */
  const createAjaxErrorCallback = cb => (
    // we need to preserve the `this` context, which will carry the ajax event context, so don't use an arrow function
    function errorCallback(xhr) {
      handle(cb)(this, xhr)
    }
  )
  const fallbackHandler = (event, xhr, settings, thrownError) => {
    /**
     * When the AJAX error has already been handled, there's nothing left to do. Make sure to reset the system so it
     * can be ready for the next AJAX error to come through the system.
     */
    if (isHandled()) {
      reset()
      return
    }

    /**
     * We do a lot of handling bad requests (4xx) by taking a re-rendered `new` view and sticking it into the DOM.
     * We don't have fallback behavior for 4xx right now. In those cases, we don't want to redirect to a misleading
     * 500 error page or something. Ideally we should have all AJAX errors handled to deal with 4xx errors gracefully,
     * but for now we're not certain about it and don't want to redirect to a /500 when it's a 4xx.
     */
    if (!isServerError(xhr)) return

    // eslint-disable-next-line no-console
    console.error('Unhandled AJAX error. Status code', xhr.status, 'Error', xhr.responseText)
    reset()
    if (isProduction()) window.location.assign('/500')
  }
  const setupFallback = () => { $(document).ajaxError(fallbackHandler) }

  return { handle, createAjaxErrorCallback, setupFallback, isHandled }
}

export const ajaxErrorHandler = createAjaxErrorHandler()

export const errorNotification = (message, title = t('.error')) => ({
  title,
  message,
  position: 'tr',
  autoDismiss: 0,
})

export const formatErrorMessage = error => (
  /Request failed with status code 500/.test(error.message) ? (
    t('.server_error_message')
  ) : (
    isAxiosNetworkError(error) ? (
      t('shared.modal_helpers.network_tertiary_text')
    ) : (
      error.message
    )
  )
)

// Put 'any' here for now, we'll probably deprecate this in favor of the notification system anyway
export const handleError = (dispatch, fn) => (error) => {
  if (error.response && error.response.data.error) {
    return dispatch(fn(error.response.data.error))
  } else if (error.response && error.response.data.errors) {
    return dispatch(fn(error.response.data.errors))
  } else {
    return dispatch(fn(error.message))
  }
}

export const parseError = (error) => {
  if (error.response && error.response.data.error) {
    return error.response.data.error
  } else if (error.response && error.response.data.errors) { // usually the format when coming back from our API
    const errors = error.response.data.errors
    return pipe(
      values,
      map(x => join(': ', reject(isBlank, [x.title, x.detail]))),
      join(', '),
    )(errors)
  } else {
    return formatErrorMessage(error)
  }
}

export const parseErrorAndReject = (error) => {
  if (error.response && error.response.data.error) {
    return Promise.reject(error.response.data.error)
  } else {
    return Promise.reject(formatErrorMessage(error))
  }
}

/**
 * Let's start consolidating the error handling paths, even if it's simple like using an alert on window. That way
 * we can more easily refactor everything when we unify the notification system.
 */
export const crappyWayToShowAlerts = (xhr) => {
  alert(path(['errors', '0', 'detail'])(xhr.responseJSON) || 'Error') // eslint-disable-line no-alert
}
