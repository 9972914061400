import { setSentryInstance } from 'shared/sentry';
import * as Sentry from '@sentry/browser';

export function initializeSentry() {
  if (!window.tcSentry) {
    setSentryInstance({
      captureException: () => {},
      setUser: () => {},
      captureMessage: () => {},
    });
    return;
  }

  Sentry.init(window.tcSentry.config);

  setSentryInstance(Sentry);
  window.Sentry = Sentry;
  window.tcSentry.afterInitialize();
}
